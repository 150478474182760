import { useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import showToast from "utils/showToast";
import enums from "../../../enums";
import { useNavigate } from "react-router-dom";
import endpoints from "constants/endpoints";
import { fetchPatchRequest } from "libs/network";

function ChangePassword() {
  const formikRef = useRef();

  const navigate = useNavigate();

  const changePassword = async (values, resetForm) => {
    const payload = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    };

    try {
      const response = await fetchPatchRequest(endpoints.auth.changePassword, payload);
      if (response.status < 400) {
        showToast(response.data.message, enums.toast.SUCCESS);
        resetForm();
        navigate("/users");
      }
    } catch (err) {
      showToast(err.data.message, enums.toast.ERROR);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Change Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={{ currentPassword: "", newPassword: "", confirmPassword: "" }}
            validationSchema={yup.object().shape({
              currentPassword: yup.string().required("Current password is required"),
              newPassword: yup
                .string()
                .required("New Password is required")
                .min(8, "Password must be 8 characters long"),
              confirmPassword: yup
                .string()
                .oneOf([yup.ref("newPassword"), null], "Password must match"),
            })}
            innerRef={formikRef}
            onSubmit={(values, { resetForm }) => {
              changePassword(values, resetForm);
            }}
          >
            {({ values, handleSubmit, handleBlur, setFieldValue }) => {
              return (
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Current password"
                      fullWidth
                      name="currentPassword"
                      value={values.currentPassword}
                      onChange={(e) => {
                        setFieldValue("currentPassword", e.target.value);
                      }}
                      handleBlur={handleBlur}
                    />
                    <MDTypography fontWeight="regular" color="error" fontSize=".85rem">
                      <ErrorMessage name="currentPassword" />
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="New password"
                      fullWidth
                      name="newPassword"
                      value={values.newPassword}
                      onChange={(e) => {
                        setFieldValue("newPassword", e.target.value);
                      }}
                      handleBlur={handleBlur}
                    />
                    <MDTypography fontWeight="regular" color="error" fontSize=".85rem">
                      <ErrorMessage name="newPassword" />
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Confirm password"
                      fullWidth
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={(e) => {
                        setFieldValue("confirmPassword", e.target.value);
                      }}
                      handleBlur={handleBlur}
                    />
                    <MDTypography fontWeight="regular" color="error" fontSize=".85rem">
                      <ErrorMessage name="confirmPassword" />
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" fullWidth type="submit">
                      Change Password
                    </MDButton>
                  </MDBox>
                </MDBox>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ChangePassword;
