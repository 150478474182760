const endpoints = {
  auth: {
    login: "/auth/login",
    me: "/auth/me",
    changePassword: "/auth/change-password",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
    logout: "/auth/logout",
  },
  user: {
    getAllUsers: "/admin/get-users",
    changeUserStatus: (id) => `admin/change-user-status/${id}`,
    getUserDetails: (id) => `/admin/user/${id}`,
  },
  dashboard: {
    getStatistics: "/admin/dashboard/get-statistics",
  },
};

export default endpoints;
