import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

// const token = localStorage.getItem("token");

export const PrivateRoute = ({ children }) => {
  const token = useSelector((state) => state.me.token);
  if (!token) {
    return <Navigate to="/authentication/sign-in" replace={true} />;
  }

  return children ? children : <Outlet />;
};

export const PublicRoute = ({ children }) => {
  const token = useSelector((state) => state.me.token);
  if (token) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  return children ? children : <Outlet />;
};
