import { createSlice } from "@reduxjs/toolkit";
import getMeData from "store/actions/getMeData";

const initialState = {
  token: null,
  data: null,
};

const { reducer, actions } = createSlice({
  name: "meReducer",
  initialState,
  reducers: {
    storeToken: (state, action) => {
      state.token = action.payload;
    },
    storeData: (state, action) => {
      state.data = action.payload;
    },
    resetMeData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeData.fulfilled.type, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const { storeData, resetMeData, storeToken } = actions;
export default reducer;
