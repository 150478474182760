import toast from "react-hot-toast";
import enums from "../enums";

const showToast = (message, type) => {
  switch (type) {
    case enums.toast.SUCCESS:
      return toast.success(message || enums.toast.SUCCESS);
    case enums.toast.ERROR:
      return toast.error(message || enums.toast.ERROR);
    default:
      break;
  }
};

export default showToast;
