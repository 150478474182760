/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { useState } from "react";
import ReactPaginate from "react-paginate";

// Expenceing page components
import WorkType from "./WorkType";
import "../../../../../utils/Pagination.css"

function index({ workType }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 5;
  const currentItems = workType?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(workType?.length / 5);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 5) % workType.length;
    setItemOffset(newOffset);
  };

  return (
    <Grid item xs={12} md={12} xl={12}>
      <Card id="delete-account">
        <MDBox pt={3} px={2}>
          <MDTypography variant="h6" fontWeight="medium">
            Work Types
          </MDTypography>
        </MDBox>
        <MDBox pt={1} pb={2} px={2}>
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {currentItems?.length > 0
              ? currentItems?.map((type) => (
                  <WorkType title={type?.title} description={type?.description} icon={type?.icon} />
                ))
              : ""}

            <ReactPaginate
              breakLabel="..."
              nextLabel=">>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<<"
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
            />
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
}

export default index;
