import { fetchGetRequest } from "../../libs/network";
import { createAsyncThunk } from "@reduxjs/toolkit";
import endpoints from "constants/endpoints";

const getMeData = createAsyncThunk(
  "me/getMeData",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await fetchGetRequest(endpoints.auth.me);
      return fulfillWithValue(response.data.user);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export default getMeData;
