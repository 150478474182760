import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { constants } from "../constants";

const http = axios.create({
  baseURL: constants.BASE_URL,
});

http.interceptors.request.use((request) => {
  const token = localStorage.getItem("token");
  if (token) {
    request.headers.Authorization = "Bearer " + token;
  }
  return request;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/authentication/sign-in";
    }
    return Promise.reject(error);
  }
);

export const fetchGetRequest = (endpoint, extraConfig = {}) => {
  return http.get(endpoint, { ...extraConfig }).catch((error) => {
    return Promise.reject(error.response);
  });
};

export const fetchPostRequest = (endpoint, payload, extraConfig = {}) => {
  return http.post(endpoint, payload, { ...extraConfig }).catch((error) => {
    return Promise.reject(error.response);
  });
};

export const fetchMultipartPostRequest = (endpoint, payload, extraConfig = {}) => {
  return http
    .post(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const fetchPutRequest = (endpoint, payload, extraConfig = {}) => {
  return http.put(endpoint, payload, { ...extraConfig }).catch((error) => {
    return Promise.reject(error.response);
  });
};

export const fetchMultipartPutRequest = async (endpoint, payload, extraConfig = {}) => {
  try {
    return await http.put(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    });
  } catch (error) {
    return error.response;
  }
};

export const fetchPatchRequest = (endpoint, payload, extraConfig = {}) => {
  return http.patch(endpoint, payload, { ...extraConfig }).catch((error) => {
    return Promise.reject(error.response);
  });
};

export const fetchMultipartPatchRequest = async (endpoint, payload, extraConfig = {}) => {
  try {
    return await http.patch(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    });
  } catch (error) {
    return error.response;
  }
};

export const fetchDeleteRequest = (endpoint, payload, extraConfig = {}) => {
  return http.delete(endpoint, payload, { ...extraConfig }).catch((error) => {
    return Promise.reject(error.response);
  });
};
