/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/users/user/components/Header";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchGetRequest } from "libs/network";
import endpoints from "constants/endpoints";
import Expences from "./components/Expences";
import Events from "./components/Events";
import Employee from "./components/Employee";
import WorkType from "./components/Worktype/";

function userDetails() {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchGetRequest(endpoints.user.getUserDetails(params.id));
      if (response.status < 400) {
        setUser(response.data.user?.[0]);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mb={2} />
      {isLoading ? (
        <MDTypography variant="h6" fontWeight="regular">
          Loading...
        </MDTypography>
      ) : (
        <Header
          avatar={
            user?.avatar ||
            "https://media.istockphoto.com/id/1016744004/vector/profile-placeholder-image-gray-silhouette-no-photo.jpg?s=612x612&w=0&k=20&c=mB6A9idhtEtsFXphs1WVwW_iPBt37S2kJp6VpPhFeoA="
          }
          name={user?.firstName + " " + user?.lastName}
          role={user?.userRole}
          tabValue={tabValue}
          setTabValue={setTabValue}
        >
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
                {tabValue == 0 ? (
                  <>
                    <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                    <ProfileInfoCard
                      title="profile information"
                      description=""
                      info={{
                        fullName: user?.firstName,
                        //   mobile: "(44) 123 1234 123",
                        email: user?.emailAddress,
                        //   location: "USA",
                      }}
                      social={[
                        {
                          link: "https://www.facebook.com/CreativeTim/",
                          icon: <FacebookIcon />,
                          color: "facebook",
                        },
                        {
                          link: "https://twitter.com/creativetim",
                          icon: <TwitterIcon />,
                          color: "twitter",
                        },
                        {
                          link: "https://www.instagram.com/creativetimofficial/",
                          icon: <InstagramIcon />,
                          color: "instagram",
                        },
                      ]}
                      shadow={false}
                      action={{ route: "", tooltip: "Edit Profile" }}
                    />

                    {/* <Divider orientation="vertical" sx={{ mx: 0 }} /> */}
                  </>
                ) : (
                  ""
                )}

                {tabValue == 1 ? (
                  <>
                    <Events events={user?.events} />
                  </>
                ) : (
                  ""
                )}

                {tabValue == 2 ? (
                  <>
                    <WorkType workType={user?.workTypes} />
                  </>
                ) : (
                  ""
                )}

                {tabValue == 3 ? (
                  <>
                    <Employee employees={user?.employees} />
                  </>
                ) : (
                  ""
                )}

                {tabValue == 4 ? (
                  <>
                    <Expences allexpences={user?.expenses} />
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </MDBox>
        </Header>
      )}
    </DashboardLayout>
  );
}

export default userDetails;
