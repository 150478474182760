import { Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import "../../../../../utils/Pagination.css";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AvatarGroup from "@mui/material/AvatarGroup";
import moment from "moment";

import { useMaterialUIController } from "context";
export default function index({ events }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 5;
  const currentItems = events?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(events?.length / 5);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 5) % events.length;
    setItemOffset(newOffset);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <MDBox pt={3} px={2}>
          <MDTypography variant="h6" fontWeight="medium">
            Events
          </MDTypography>
        </MDBox>
        {currentItems?.length > 0
          ? currentItems.map((event, i) => (
              <Grid item xs={12} md={12} xl={12}>
                <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ flexShrink: 0 }}>{event?.title}</Typography>
                    {/* <Typography sx={{ color: "text.secondary" }}>{employee?.fullName}, {employee?.employeeType} </Typography>
            <Typography sx={{ color: "text.secondary" }}>{employee?.fullName}, {employee?.employeeType} </Typography> */}
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems={{ xs: "flex-start", sm: "center" }}
                          flexDirection={{ xs: "column", sm: "row" }}
                          mb={2}
                        >
                          <MDTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            {event?.description}
                          </MDTypography>

                          <MDBox
                            display="flex"
                            alignItems="center"
                            mt={{ xs: 2, sm: 0 }}
                            ml={{ xs: -1.5, sm: 0 }}
                          >
                            <MDBox mr={1}>
                              <AvatarGroup max={4}>
                                {event?.teams?.length > 0
                                  ? event?.teams.map((ev) => (
                                      <Tooltip title={ev?.fullName}>
                                        <Avatar
                                          alt={ev?.fullName}
                                          src="/static/images/avatar/1.jpg"
                                        />
                                      </Tooltip>
                                    ))
                                  : ""}
                              </AvatarGroup>
                            </MDBox>
                          </MDBox>
                        </MDBox>

                        <MDBox mb={1} lineHeight={0}>
                          <MDTypography variant="caption" color="text">
                            Event Start Date:&nbsp;&nbsp;&nbsp;
                            <MDTypography
                              variant="caption"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              {moment(event?.eventStartDate).format("lll")}
                            </MDTypography>
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={1} lineHeight={0}>
                          <MDTypography variant="caption" color="text">
                            Event End Date:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium">
                              {moment(event?.eventEndDate).format("lll")}
                            </MDTypography>
                          </MDTypography>
                        </MDBox>

                        <MDBox mb={1} lineHeight={0}>
                          <MDTypography variant="caption" color="text">
                            Contact Person Name:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium">
                              {event?.contactPersonName}
                            </MDTypography>
                          </MDTypography>
                        </MDBox>

                        <MDBox mb={1} lineHeight={0}>
                          <MDTypography variant="caption" color="text">
                            Contact Person Number:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium">
                              {event?.contactPersonNumber}
                            </MDTypography>
                          </MDTypography>
                        </MDBox>

                        <MDBox mb={1} lineHeight={0}>
                          <MDTypography variant="caption" color="text">
                            Address:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium">
                              {event?.address}, {event?.city}
                            </MDTypography>
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))
          : ""}

        <br />
        <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<<"
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </Grid>
    </>
  );
}
