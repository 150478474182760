import { useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { fetchPostRequest } from "libs/network";
import showToast from "utils/showToast";
import enums from "../../../enums";
import { useDispatch } from "react-redux";
import { storeToken } from "../../../store/reducers/me";
import { useNavigate } from "react-router-dom";
import endpoints from "constants/endpoints";

function Signin() {
  const formikRef = useRef();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleChangeRememberMe = () => {
    formikRef.current.setFieldValue("rememberMe", !formikRef.current.values.rememberMe);
  };

  const login = async (values, resetForm) => {
    const payload = {
      accountType: "Administrator",
      emailAddress: values.email,
      password: values.password,
      rememberMe: values.rememberMe,
    };

    try {
      const response = await fetchPostRequest(endpoints.auth.login, payload);
      if (response.status < 400) {
        localStorage.setItem("token", response.data.token);
        dispatch(storeToken(response.data.token));
        showToast(response.data.message, enums.toast.SUCCESS);
        resetForm();
        navigate("/dashboard");
      }
    } catch (err) {
      showToast(err.data.message, enums.toast.ERROR);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={{ email: "", password: "", rememberMe: false }}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .email("Must be a valid email")
                .required("Email is required")
                .trim(),
              password: yup.string().required("Password is required"),
              rememberMe: yup.boolean(),
            })}
            innerRef={formikRef}
            onSubmit={(values, { resetForm }) => {
              login(values, resetForm);
            }}
          >
            {({ values, handleSubmit, handleBlur, setFieldValue }) => {
              return (
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      fullWidth
                      name="email"
                      value={values.email}
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                      handleBlur={handleBlur}
                    />
                    <MDTypography fontWeight="regular" color="error" fontSize=".85rem">
                      <ErrorMessage name="email" />
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Password"
                      fullWidth
                      name="password"
                      value={values.password}
                      onChange={(e) => {
                        setFieldValue("password", e.target.value);
                      }}
                      handleBlur={handleBlur}
                    />
                    <MDTypography fontWeight="regular" color="error" fontSize=".85rem">
                      <ErrorMessage name="password" />
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={values.rememberMe} onChange={handleChangeRememberMe} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleChangeRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" fullWidth type="submit">
                      sign in
                    </MDButton>
                  </MDBox>
                </MDBox>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Signin;
