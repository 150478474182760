import moment from "moment";

const useChartDataGenerator = (data) => {
  const labels = [];
  const dataSets = [];

  data?.forEach((item) => {
    labels.push(moment(item.month).format("MMM"));
    dataSets.push(item.count);
  });

  const chartData = {
    labels,
    datasets: { label: "Sales", data: dataSets },
  };

  return chartData;
};

export default useChartDataGenerator;
