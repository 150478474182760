// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import { fetchGetRequest } from "libs/network";
import getMeData from "store/actions/getMeData";
import { useDispatch, useSelector } from "react-redux";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";
import { Button } from "@mui/material";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import { fetchPatchRequest } from "libs/network";
import showToast from "utils/showToast";
import enums from "../../enums";
import endpoints from "constants/endpoints";
import ReactPaginate from "react-paginate";
import MDInput from "components/MDInput";
import moment from "moment/moment";
import "../../utils/Pagination.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SortingIndicator from "components/SortingIndicator";

function Users() {
  const [users, setUsers] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [totalItems, setTotalItems] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [searchInput, setSearchInput] = useState("");

  const [orderKey, setOrderKey] = useState("firstName");
  const [orderType, setOrderType] = useState("asc");

  const [sortedColumns, updateSortColumns] = useState({
    firstName: "asc",
    createdAt: "asc",
  });

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const columns = [
    { Header: "user", accessor: "user", width: "45%", align: "left" },
    { Header: "role", accessor: "role", align: "left" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "created at", accessor: "created_at", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    setRowData(() =>
      users?.length > 0
        ? users?.map((us) => {
            return {
              user: us?.fullName,
              role: us?.userRole,
              status: (
                <Switch
                  {...label}
                  checked={us.isActive}
                  onChange={() => changeStatus(us?.userId)}
                  color="secondary"
                />
              ),
              created_at: moment(us?.registerdOn).format("MMM Do YY"),
              action: (
                <Link to={`/users/${us?.userId}`}>
                  <Button variant="text" size="small">
                    View
                  </Button>
                </Link>
              ),
            };
          })
        : []
    );
  }, [users]);

  useEffect(() => {
    getUsersData();
  }, [pageNum, pageSize, searchInput]);

  const getUsersData = async () => {
    try {
      const params = {
        page: pageNum,
        size: pageSize,
        orderKey, // will be change later
        orderType, // will be change later
        keywords: searchInput,
      };

      const response = await fetchGetRequest(endpoints.user.getAllUsers, { params });
      if (response.status < 400) {
        setUsers(response.data.users.data);
        setTotalItems(response.data.users.metadata.totalItems);
        setLastPage(response.data.users.metadata.lastPage);
      }
    } catch (err) {
      console.log(err);
      if (err.status === 404) {
        setUsers([]);
      }
    }
  };

  const changeStatus = async (id) => {
    try {
      const response = await fetchPatchRequest(endpoints.user.changeUserStatus(id));
      if (response.status < 400) {
        showToast(response?.data?.message, enums.toast.SUCCESS);
        getUsersData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const paginate = (e) => {
    setPageNum(e.selected + 1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDInput
        label="Search here"
        onChange={(e) => {
          setPageNum(1);
          setSearchInput(e.target.value);
        }}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Users
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: rowData }}
                  isSorted={true}
                  entriesPerPage={{ defaultValue: pageSize }}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
            {totalItems > pageSize ? (
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={lastPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={paginate}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            ) : null}
            <MDBox>
              <Select
                value={pageSize}
                onChange={(e) => {
                  setPageNum(1);
                  setPageSize(e.target.value);
                }}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Users;
